import {connect} from "react-redux";
import {withRouter} from "react-router";
import React, {useEffect, useState} from "react";
import './index.scss';
import {makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {ButtonBlack} from "../../common/components/color-button";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'black'
  },
  mine: {
    backgroundColor: 'rgb(50,50,50)'
  }
}));

const TestComp = ({...props}) => {
  console.log("Props", props);
  return <>
    <b>Tekst</b>
    {props.children}
    </>
}

const VotingElo = ({user}) => {
  const classes = useStyles();
  const [voteData, setVoteData] = useState([
    {entry: 1, score: 0, played: [], count: 0},
    {entry: 2, score: 0, played: [], count: 0},
    {entry: 3, score: 0, played: [], count: 0},
    {entry: 4, score: 0, played: [], count: 0},
    {entry: 5, score: 0, played: [], count: 0},
    {entry: 6, score: 0, played: [], count: 0},
    {entry: 7, score: 0, played: [], count: 0},
    {entry: 8, score: 0, played: [], count: 0},
  ]);
  const [images, setImages] = useState([
    {id: 1, url: '/images/facebook-1.jpg'},
    {id: 2, url: '/images/facebook-2.jpg'},
    {id: 3, url: '/images/facebook-3.jpg'},
    {id: 4, url: '/images/facebook-4.jpg'},
    {id: 5, url: '/images/facebook-5.jpg'},
    {id: 6, url: '/images/facebook-6.jpg'},
    {id: 7, url: '/images/facebook-7.jpg'},
    {id: 8, url: '/images/facebook-8.jpg'},
    {id: 9, url: '/images/facebook-9.jpg'},
  ]);

  const [voting, setVoting] = useState({});
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    if (voteData) {
      console.log(voteData);
      setNext();
    }
  }, [voteData])

  const setNext = () => {
    let firstPossibleCount = voteData.reduce((a, b) => a.count <= b.count ? a : b);
    let firstPossible = voteData.filter(entry => entry.count === firstPossibleCount.count);
    let first = firstPossible.reduce((a, b) => a.score >= b.score ? a : b);

    let secondPossiblesNotPlayed = voteData.filter(entry => entry.entry !== first.entry && first.played.indexOf(entry.entry) === -1);
    if (secondPossiblesNotPlayed.length === 0) {
      setVoting({});
      setFinished(true);
      return;
    }
    let secondPossiblesCount = secondPossiblesNotPlayed.reduce((a, b) => a.count <= b.count ? a : b);
    let secondPossibles = secondPossiblesNotPlayed.filter(entry => entry.count === secondPossiblesCount.count);
    let second = secondPossibles.reduce((a, b) => a.score >= b.score ? a : b);

    if (Math.floor((Math.random() * 9) + 1) <= 5) {
      let swap = first;
      first = second;
      second = swap;
    }
    let obj = {
      first: images.find(i => i.id === first.entry),
      second: images.find(i => i.id === second.entry)
    };
    setVoting(obj);
  }

  const score = (outcome) => {
    console.log("Outcome", outcome);
    let newVoteData = [...voteData];
    let first = newVoteData.find(entry => entry.entry === voting.first.id);
    let second = newVoteData.find(entry => entry.entry === voting.second.id);
    if (!first.played.some(entry => entry === second.entry) &&
      !second.played.some(entry => entry === first.entry)) {
      switch (outcome) {
        case 'first':
          first.score += 1;
          break;
        case 'second':
          second.score += 1;
          break;
        default:
          first.score += 0.5;
          second.score += 0.5;
      }
      first.played.push(second.entry);
      second.played.push(first.entry);
      first.count++;
      second.count++;
      setVoteData(newVoteData);
    }
  }

  return <>
    <Box width={1} className={classes.root}>

      <div className={"full-height p-4"}>
        <Row className="align-content-center text-center text-white animate fadeIn">
          <Col md={5} className="flex-center">
            {!finished && voting && voting.first &&
            <div className="img-container flex-center" onClick={() => score('first')}>
              <img className={"img-fluid shadow image"} src={voting.first.url}/>
              <div className="middle">
                <div className="text">BESTE BILDE</div>
              </div>
            </div>
            }
          </Col>
          <Col md={2} onClick={() => score('equal')}>
            <ButtonBlack disableRipple className="equalButton">Like fine</ButtonBlack>
          </Col>
          <Col md={5} className="flex-center">
            {!finished && voting && voting.second &&
            <div className="img-container flex-center" onClick={() => score('second')}>
              <img className={"img-fluid shadow image"} src={voting.second.url}/>
              <div className="middle">
                <div className="text">BESTE BILDE</div>
              </div>
            </div>
            }
          </Col>
        </Row>
      </div>

    </Box>
  </>
}


const mapStateToProps = state => ({
  user: state.user.user
});

const connected = connect(mapStateToProps, {})(VotingElo);

export default withRouter(connected);
