import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useDropzone} from "react-dropzone";
import {API_BASE_URL} from "../../constants";
import {toast} from "react-toastify";
import imageCompression from 'browser-image-compression';
import {drawFileInCanvas} from "browser-image-compression/lib/utils";
import {max} from "moment";

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  cursor: 'pointer',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

const JpgDropzone = ({count, onUploaded, uploadPath, initFiles, onUploading}) => {
  const [files, setFiles] = useState([]);
  const maxSize = 100000000;

  useEffect(() => {

  }, [initFiles]);

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length > count) {
      return toast.error('Kun ' + count + " bilde" + (count > 1 ? "r" : ""));
    }
    setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));
  }, []);

  const {isDragActive, getRootProps, getInputProps, isDragReject, isDragAccept, acceptedFiles, rejectedFiles} = useDropzone({
    onDrop,
    accept: 'image/jpeg',
    minSize: 0,
    maxSize: maxSize,
  });

  const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject
  ]);

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));

    // Send files
    files.forEach(file => {
      uploadFile(file);
    })
  }, [files]);

  const resizeImage = async (file) => {
    let [img, canvas] = await drawFileInCanvas(file);
    if (file.size <= 5 * 1024 * 1024 && canvas.height <= 4096 && canvas.width <= 4096)
      return file;

    const options = {
      maxSizeMB: 5,
      maxWidthOrHeight: 4096,
      useWebWorker: false
    }
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  }

  const uploadFile = async (file) => {
    console.log("Sending file");
    if (onUploading)
      onUploading(true);
    const fd = new FormData();

    const newFile = await resizeImage(file);
    fd.append('file', newFile);
    fetch(uploadPath ? uploadPath : API_BASE_URL + "/image/upload/evaluation/image", {
      // content-type header should not be specified!
      method: 'POST',
      body: fd,
      credentials: 'include',
    })
      .then(response => response.json())
      .then(success => {
        if ('data' in success && 'uuid' in success.data) {
          onUploaded(success.data.uuid)
          if (onUploading)
            onUploading(false);
        } else {
          files.forEach(file => URL.revokeObjectURL(file.preview));
          setFiles([]);
          if (onUploading)
            onUploading(false);
          return toast.error('Feil med opplasting av bildet');
        }
      })
      .catch(error => {
          console.log(error)
          if (onUploading)
            onUploading(false);
        }
      );
  }

  return (
    <section className="container">
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        {!isDragActive && 'Klikk her eller slipp en fil for å laste opp. Maks størrelse er 5MB og 4096 piksler en vei. Alt over det vil bli nedjustert automatisk uten at du har kontroll på hvordan resultatet blir.'}
        {isDragActive && !isDragReject && "Slipp for å laste opp!"}
        {isDragReject && "Filtypen er ikke akseptert, beklager!"}
        {isFileTooLarge && (
          <div className="text-danger mt-2">
            Filen er for stor. Må være under {maxSize}B.
          </div>
        )}
        <em>(Kun *.jpg er akseptert)</em>
        <aside style={thumbsContainer}>
          {thumbs}
        </aside>
      </div>
    </section>
  );
}

export default JpgDropzone;
