import React, {Fragment, useEffect, useRef, useState} from "react";

import {makeStyles} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import 'moment/locale/nb';
import Evaluation from "./Evaluation";
import {Modal} from 'react-bootstrap';
import UploadToEvaluation from "./UploadToEvaluation";
import {ButtonBlack, ButtonWhite} from "../../common/components/color-button";
import {Fetch} from "../../common/lib/api-fetch";
import {API_BASE_URL} from "../../constants";
import {toast} from "react-toastify";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {getVotings} from "./actions";
import Lightbox from 'react-image-lightbox';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DataTable from "react-data-table-component";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: '100vh'
  },
  toolbar: {
    backgroundColor: 'rgb(0,0,0,0)'
  }
}));

const VotingDashboard = ({history, user, evaluations, getVotings}) => {
  const classes = useStyles();
  const [currentEvaluation, setCurrentEvaluation] = useState(null);
  const [evaluationsImages, setEvaluationsImages] = useState(null);
  const [evaluationsImageCount, setEvaluationsImageCount] = useState(null);
  const [evaluationsImageGroupCount, setEvaluationsImageGroupCount] = useState(null);
  const [lbImages, setLbImages] = useState(null);
  const [lbImagesIdx, setLbImagesIdx] = useState(0);
  const [value, setValue] = React.useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [archiveData, setArchiveData] = useState([]);

  let uploadRef = useRef(null);

  let archiveDate = new Date();
  archiveDate.setDate(archiveDate.getDate() - 7);

  useEffect(() => {
    getVotings();
  }, []);

  const fetchImageData = async () => {
    let newImages = {};
    for (const evaluation of evaluations) {
      if (!isArchived(evaluation)) {
        await Fetch(API_BASE_URL + "/voting/" + evaluation.id + "/images").then(result => {
          newImages[0 + evaluation.id] = result.data.images;
        })
      }
    }
    setEvaluationsImages(newImages);
    let newCount = {};
    for (const evaluation of evaluations) {
      if (!isArchived(evaluation)) {
        await Fetch(API_BASE_URL + "/voting/" + evaluation.id + "/imageCount").then(result => {
          newCount[0 + evaluation.id] = result.data;
        })
      }
    }
    setEvaluationsImageCount(newCount);
    let groupCount = {};
    for (const evaluation of evaluations) {
      if (evaluation.group_name && Number(evaluation.id in newImages)) {
        if (!(evaluation.group_name in groupCount))
          groupCount[evaluation.group_name] = 0;
        groupCount[evaluation.group_name] += newImages[Number(evaluation.id)].length;
      }
    }
    setEvaluationsImageGroupCount(groupCount);
    let ad = [];
    for (const evaluation of evaluations) {
      if (isArchived(evaluation)) {
        let row = {
          no: evaluation.id,
          title: evaluation.title,
          evaluation: evaluation,
        }
        ad.push(row);
      }
    }
    ad.sort((a, b) => b.evaluation.id - a.evaluation.id);
    setArchiveData(ad);
  }

  useEffect(() => {
    fetchImageData();
  }, [evaluations]);

  const saveEvaluation = () => {
    Fetch(API_BASE_URL + "/voting/upload", 'POST', {
      votingId: currentEvaluation.id,
      images: uploadRef.current.images
    }).then(result => {
      if (result.status === 200) {
        setCurrentEvaluation(null);
        fetchImageData();
      } else {
        toast.error('Feil ved opplasting av bildene. Prøv igjen');
      }
    });

  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isArchived = (evaluation) => {
    switch (evaluation.categoryId) {
      case 3:
        if (new Date(evaluation.stopUploadDate) < archiveDate)
          return true;
      default:
        if (new Date(evaluation.resultDate) < archiveDate)
          return true;
    }
    return false;
  }

  const getEvaluationImage = async (evaluation) => {
    let id = 0 + evaluation.id;
    if (!(id in evaluationsImages)) {
      await Fetch(API_BASE_URL + "/voting/" + evaluation.id + "/images").then(result => {
        setEvaluationsImages({...evaluationsImages, [id]: result.data.images});
      })
    }
  }

  const getEvaluationImageCount = async (evaluation) => {
    let id = 0 + evaluation.id;
    if (!(id in evaluationsImageCount)) {
      await Fetch(API_BASE_URL + "/voting/" + evaluation.id + "/imageCount").then(result => {
        setEvaluationsImageCount({...evaluationsImageCount, [id]: result.data});
      })
    }
  }

  const ShowEvaluation = ({data}) => {
    let evaluation = data.evaluation;
    getEvaluationImage(evaluation);
    getEvaluationImageCount(evaluation);
    return showEvaluation(evaluation);
  }

  const showEvaluation = (evaluation) => {
    return (
      <Fragment key={evaluation.id}>
        <Row>
          <Col md={10}>
            <Evaluation evaluation={evaluation} setEvaluation={setCurrentEvaluation}
                        images={evaluationsImages && evaluation.id in evaluationsImages ? evaluationsImages[evaluation.id] : null}
                        imageCount={evaluationsImageCount && evaluation.id in evaluationsImageCount ? evaluationsImageCount[evaluation.id] : null}
                        groupCount={evaluationsImageGroupCount && evaluation.group_name in evaluationsImageGroupCount ? evaluationsImageGroupCount[evaluation.group_name] : null}
            />
          </Col>
          {
            <Col xs={6} md={2} className="justify-content-center align-self-center">
              <Container>
                {
                  evaluationsImages && evaluation.id in evaluationsImages &&
                  evaluationsImages[evaluation.id].map(image => {

                    return (
                      <Fragment key={image.uuid}>
                        <Row>
                          <Col>
                            <img src={API_BASE_URL + "/image/" + image.uuid}
                                 alt={"Type"}
                                 className="mt-2 shadow img-fluid rounded mx-auto d-block"
                                 onClick={() => {
                                   setLbImagesIdx(0);
                                   setLbImages([API_BASE_URL + "/image/" + image.uuid])
                                 }}
                            />
                          </Col>
                        </Row>
                      </Fragment>
                    )
                  })
                }
              </Container>
            </Col>
          }
        </Row>
        <Row>
          <Col>
            <hr></hr>
          </Col>
        </Row>
      </Fragment>
    )
  }

  const archiveColumns = [
    {
      name: 'Tittel',
      selector: 'title',
      sortable: false,
    }
  ];

  const isExpanded = row => row.expanded;

  return (
    <>
      <Container className={classes.root}>
        <Row className="justify-content-center" md={8}>
          <Container>
            <>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                centered
              >
                <Tab label="Pågående konkurranser"/>
                <Tab label="Arkiv"/>
              </Tabs>
              <div hidden={value !== 0}>
                {
                  evaluations.map(evaluation => {
                    if (!isArchived(evaluation))
                      return showEvaluation(evaluation)
                  })
                }
              </div>
              <div hidden={value !== 1}>
                <DataTable
                  title={"Arkiv"}
                  columns={archiveColumns}
                  data={archiveData}
                  defaultSortField="no"
                  defaultSortAsc={false}
                  expandableRows
                  expandableRowsComponent={<ShowEvaluation/>}
                  expandOnRowClicked
                  highlightOnHover
                  striped
                  expandableRowExpanded={isExpanded}
                />
              </div>
            </>
          </Container>
        </Row>
      </Container>
      {lbImages !== null && (
        <Lightbox
          mainSrc={lbImages[lbImagesIdx]}
          onCloseRequest={() => setLbImages(null)}
        />
      )}
      <Modal size="lg" show={currentEvaluation !== null} onHide={() => setCurrentEvaluation(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Last opp bilder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UploadToEvaluation ref={uploadRef} evaluation={currentEvaluation} onUploading={setIsUploading}
                              oldImages={currentEvaluation ? evaluationsImages[currentEvaluation.id] : []}
                              groupCount={currentEvaluation && evaluationsImageGroupCount && currentEvaluation.group_name in evaluationsImageGroupCount ? evaluationsImageGroupCount[currentEvaluation.group_name] : null}

          />
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-around mt-3">
            <ButtonWhite onClick={() => setCurrentEvaluation(null)}>
              Avbryt
            </ButtonWhite>
            <ButtonBlack onClick={saveEvaluation} disabled={isUploading}>
              Lagre
            </ButtonBlack>
          </div>
        </Modal.Footer>
      </Modal>

      {user && user.roles && user.roles.some(obj => obj.roleName === 'ADMIN') &&
      <div className="fixed-action-btn button-create">
        <div onClick={() => history.push("/setup/0")} className="btn-floating btn-lg green">
          <i className="fas fa-plus"></i>
        </div>
      </div>
      }
    </>
  )
}

const mapStateToProps = state => ({
  user: state.user.user,
  evaluations: state.voting.evaluations
});

const connected = connect(mapStateToProps, {getVotings})(VotingDashboard);

export default withRouter(connected);
