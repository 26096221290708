import {connect} from "react-redux";
import {withRouter} from "react-router";
import {Fetch} from "../../common/lib/api-fetch";
import React, {useEffect, useState} from "react";
import {API_BASE_URL} from "../../constants";
import {makeStyles} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'black',
    paddingTop: "20px"
  },
  mine: {
    backgroundColor: 'rgb(50,50,50)'
  }
}));

const YearPoints = () => {
  const classes = useStyles();
  const [votings, setVotings] = useState(null);
  const [points, setPoints] = useState(null);

  const fetchPointsData = async () => {
    await Fetch(API_BASE_URL + "/voting/points", 'GET', {}).then(result => {
      setVotings(result.data);
    })
  }

  useEffect(() => {
    if (votings) {
      let points = {};
      for (let voting of votings) {
        for (let image of voting.images) {
          if (!(image.ownerId in points))
            points[image.ownerId] = {name: image.fullname, score: 0, place: 0};
          points[image.ownerId].score += image.score;
        }
      }
      let data = [];
      for (let ownerId of Object.keys(points)) {
        data.push({id: ownerId, ...points[ownerId]});
      }
      data.sort((a, b) => b.score - a.score);
      for (let pos = 0; pos < data.length; pos++) {
        let place = pos + 1;
        if (pos > 0 && data[pos].score === data[pos - 1].score)
          place = data[pos].place;
        data[pos].place = place;
      }
      setPoints(data);
    }
  }, [votings])

  useEffect(() => {
    fetchPointsData();
  }, [])

  return <>
    {points &&
    <Box width={1} className={classes.root}>
      <Container className={classes.root}>
        <div className={"full-height"}>
          <h1 className={"white-text"}>Poeng i årskonkurransen</h1>
          {points.map(owner => <Row key={owner.id}
                                    className="align-content-center text-center text-white animate fadeIn">
              <Col xs={4} md={1}>
                <span>{owner.place}. plass</span>
              </Col>
              <Col xs={4} md={2}>
                <span>{owner.name}</span>
              </Col>
              <Col xs={4} md={2}>
                <span>{owner.score}</span>
              </Col>
            </Row>
          )}
        </div>
      </Container>
    </Box>
    }
  </>
}

const mapStateToProps = state => ({
  user: state.user.user
});

const connected = connect(mapStateToProps)(YearPoints);

export default withRouter(connected);
