import React, {useEffect, useState} from "react";
import {API_BASE_URL} from "../../../constants";
import {showError} from "../../../common/lib/show-toast";
import {MDBBtn, MDBDataTable} from "mdbreact";
import {Fetch} from "../../../common/lib/api-fetch";
import Modal from "react-bootstrap/Modal";
import '../admin.scss';
import Group from "./Group";

const _ = require('lodash');

const dataTableColumns = [
    {
        label: 'Navn',
        field: 'name',
        width: 200
    }
]

export const Groups = () => {
    const [groupId, setGroupId] = useState(null);
    const [fixedData, setFixedData] = useState({
        columns: dataTableColumns,
        rows: []
    });

    const fetchData = async () => {
        await Fetch(API_BASE_URL + "/group/all", 'GET').then(result => {
            const data = result.data;
            let rows = [];
            if (_.isArray(data)) {
                for (let r of data) {
                    let row = {
                        name: r.name,
                        clickEvent: () => setGroupId(r.id)
                    };
                    rows.push(row);
                }
            }
            setFixedData({...fixedData, rows: rows});
        }).catch(error => {
            showError(error);
        })
    };

    useEffect(() => {
        fetchData();
    }, [])

    const onFinished = () => {
        setGroupId(null);
        fetchData();
    }
    return (
        <>
            <div>
                <MDBDataTable
                    className={"pointer"} striped hover
                    data={fixedData}
                    searchLabel={"Søk"}
                    paginationLabel={["Forrige", "Neste"]}
                    infoLabel={["Viser", "til", "av", "rader"]}
                    entriesLabel="Vis antall rader"
                    entries={5}
                    entriesOptions={[20, 50, 100]}
                />
                <div className="fixed-action-btn button-create">
                    <div onClick={() => setGroupId(-1)} className="btn-floating btn-lg green">
                        <i className="fas fa-plus"></i>
                    </div>
                </div>
            </div>

            <Modal
                show={groupId !== null}
                onHide={() => setGroupId(null)}
                dialogClassName="modal-70w"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Gruppe</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Group groupId={groupId} onFinished={onFinished}/>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-around">
                        <MDBBtn onClick={() => setGroupId(null)} className="blue-gradient">
                            Lukk
                        </MDBBtn>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}
