import {Fetch} from "../../../common/lib/api-fetch";
import {API_BASE_URL} from "../../../constants";
import {showError, showInfo} from "../../../common/lib/show-toast";
import React, {useEffect, useState} from "react";
import {informationToObject} from "../../../modules/information/information";
import useForm from "../../../modules/forms/useForm";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import GITextInput from "../../../modules/information/GITextInput";
import {MDBBtn} from "mdbreact";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {xorBy} from "lodash";
import {UsersSelect} from "../../../common/select/UsersSelect";

const _ = require('lodash');

const Group = ({groupId, onFinished}) => {
    const [group, setGroup] = useState(null);
    const [originalUsers, setOriginalUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);

    const fetchGroup = async () => {
        try {
            await Fetch(API_BASE_URL + "/group/" + groupId, "GET", {}).then(result => {
                setGroup(result.data);
            })
        } catch (error) {
            showError(error);
        }
    }

    const fetchUsers = async () => {
        try {
            await Fetch(API_BASE_URL + "/group/" + groupId + "/users", "GET", {}).then(result => {
                setOriginalUsers(result.data);
                setUsers([...result.data]);
            })
        } catch (error) {
            showError(error);
        }
    }

    const fetchAllUsers = async () => {
        try {
            await Fetch(API_BASE_URL+"/group/all/users", "GET").then(result => {
                setAllUsers(result.data);
            })
        } catch(error) {
            showError(error);
        }
    }

    useEffect(() => {
        fetchAllUsers();
        if (groupId === -1 || groupId === null || groupId === undefined) return;
        fetchGroup();
        fetchUsers();
    }, [groupId])

    const doValidate = (values) => {
        let errors = {};
        if (!values) return errors;

        return errors;
    }

    const saveUpdatedUsers = async (id) => {
        if (usersChanged()) {
            const data = {
                ids: users.map(user => user.id)
            };

            await Fetch(API_BASE_URL + "/group/"+id+"/users", "PUT", data).then(result => {
                if (id!==groupId) showInfo(`Gruppemedlemene er oppdatert`);
            })
        }
    }

    const saveGroup = async () => {
        try {
            if (isChanged) {
                const data = {
                    name: values.name
                }
                await Fetch(API_BASE_URL + "/group/" + groupId, "PUT", data).then(result => {
                    if (groupId === -1) {
                        showInfo("Gruppen er opprettet");
                        saveUpdatedUsers(result.data.id);
                    } else {
                        showInfo("Gruppen er oppdatert");
                        saveUpdatedUsers(groupId);
                    }
                })
            } else {
                await saveUpdatedUsers(groupId);
            }

        } catch (error) {
            showError(error);
        } finally {
            onFinished();
        }
    }

    let initValues = groupId !== -1 ? informationToObject(group) : {};
    const {values, errors, isChanged, handleChange, handleSubmit} = useForm(initValues, saveGroup, doValidate);

    const usersChanged = () => xorBy(originalUsers, users, 'id').length > 0;

    return (
        <Form>
            <Form.Row>
                <Form.Group as={Col} controlId="name">
                    <GITextInput
                        label={"Gruppenavn"}
                        name={"name"}
                        value={values && values.name ? values.name : ''}
                        valid={errors.name}
                        handleChange={handleChange}
                        onError={errors.name}
                    />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId={"users"}>
                    <Form.Label column sm={"4"} className={"pl-0"}>
                        Medlemmer
                    </Form.Label>
                    <UsersSelect allUsers={allUsers} users={users} onChange={setUsers}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <MDBBtn onClick={handleSubmit} color={"success"}
                        disabled={(!isChanged && !usersChanged()) || !_.isEmpty(errors)}>{groupId !== -1 ? "Oppdater" : "Opprett"}</MDBBtn>
            </Form.Row>
        </Form>
    );

}

const mapStateToProps = state => ({});

const connected = connect(mapStateToProps, {})(Group);

export default withRouter(connected);
