import {MDBInput} from "mdbreact";
import React from "react";

const GITextInput = ({label, name, value, handleChange, valid}) => {

    return (
        <MDBInput
            className={valid ? "form-control is-invalid" : "form-control is-valid"}
            type="text"
            label={label}
            name={name}
            value={value}
            onChange={handleChange}
        />
    )
};


export default GITextInput;
