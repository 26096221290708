import React, {Fragment, useEffect, useState, forwardRef, useImperativeHandle} from "react";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import {formatDate} from "../../common/lib/date";
import JpgDropzone from "../../common/components/jpg-dropzone";

import _ from 'lodash';
import TextField from "@material-ui/core/TextField";
import {Image} from "react-bootstrap";
import {API_BASE_URL} from "../../constants";
import {ButtonBlack} from "../../common/components/color-button";

const UploadToEvaluation = forwardRef(({evaluation, oldImages, onUploading, groupCount}, ref) => {
  const [images, setImages] = useState([]);
  const [deletedImage, setDeletedImage] = useState([]);

  useImperativeHandle(ref, () => {
    return {
      images: images
    }
  });

  useEffect(() => {
    if (evaluation != null && images.length === 0) {
      const res = [...Array(evaluation.imageCount)].map((_, i) => {
        return {
          title: '',
          uuid: ''
        }
      });
      setImages(res);
    }
  }, [evaluation]);

  useEffect(() => {
    if (oldImages)
      setImages(_.cloneDeep(oldImages));
  }, [oldImages])

  const changeTitle = i => event => {
    const newImages = _.cloneDeep(images);
    if (!(i in newImages)) {
      newImages[i] = {
        title: '',
        uuid: ''
      }
    }
    newImages[i].title = event.target.value;
    setImages(newImages);
  };

  const storeUuid = i => uuid => {
    const newImages = _.cloneDeep(images);
    if (!(i in newImages)) {
      newImages[i] = {
        title: '',
        uuid: ''
      }
    }
    newImages[i].uuid = uuid;
    setImages(newImages);
  };

  const deleteOld = (i) => {
    if (images[i].uuid===oldImages[i].uuid) {
      const newImages = _.cloneDeep(images);
      newImages[i].uuid = '';
      newImages[i].title = '';
      setImages(newImages);
    }
    setDeletedImage([...deletedImage, i]);

  }

  return (
    <div id={"upload"} className={"upload"}>
      {evaluation !== null &&
      <Container maxWidth={"md"}>
        <Box className={"box"}>
          <h3>Konkurranse</h3>
          <div className="d-flex flex-column">
            <div className="d-flex flex-row">
              <div>Tittel:</div>
              <div>{evaluation.title}</div>
            </div>
            <div className={"d-flex flex-row"}>
              <div>Tema:</div>
              <div>{evaluation.theme} {evaluation.themePoints > 0 ? "(" + evaluation.themePoints + ")" : ""}</div>
            </div>
            <div className={"d-flex flex-row"}>
              <div>Antall bilder: </div>
              <div>{oldImages.length}/{evaluation.imageCount}</div>
            </div>
            {groupCount!==null && <><div className={"d-flex flex-row"}>
              <div>Gruppe: </div>
              <div>{evaluation.group_name}</div>
            </div>
              <div className={"d-flex flex-row"}>
                <div>Antall bilder i gruppe:</div>
                <div>{groupCount} / 2</div>
              </div>
            </>}
            <div className={"d-flex flex-row"}>
              <div>Start for opplasting av bilder: </div>
              <div>{formatDate(evaluation.uploadDate)}</div>
            </div>
            <div className={"d-flex flex-row"}>
              <div>Interval for stemming: </div>
              <div>{formatDate(evaluation.voteStartDate)} - {formatDate(evaluation.voteStopDate)}</div>
            </div>
            <div className={"d-flex flex-row"}>
              <div>Resultat: </div>
              <div>{formatDate(evaluation.resultDate)}</div>
            </div>
            {evaluation.themePoints > 0 &&
            <div className="d-flex flex-row">
              <div>Poeng for tema: </div>
              <div>{evaluation.themePoints}</div>
            </div>
            }
          </div>
          <form noValidate autoComplete="off">
            <div className="d-flex flex-column">
              {_.times(evaluation.imageCount, (i) => (
                  <Fragment key={i}>
                    <div className="d-block mx-auto w-100">
                      <TextField
                        id={"title" + i}
                        label="Tittel"
                        name={"title" + i}
                        placeholder="Tittel"
                        margin="normal"
                        variant="outlined"
                        value={(images.length > i && images[i].title) || ''}
                        onChange={changeTitle(i)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                      />
                    </div>
                    {oldImages.length > i && oldImages[i].uuid && !deletedImage.includes(i) &&
                    <>
                      <Image className="mb-2" src={API_BASE_URL + "/image/" + oldImages[i].uuid} fluid/>
                      <ButtonBlack variant="contained" onClick={() => deleteOld(i)}>Fjern fra konkurransen</ButtonBlack>
                    </>
                    }
                    <Divider className="mb-2"/>
                    <JpgDropzone className="d-block w-100" count={1} onUploaded={storeUuid(i)} onUploading={onUploading}/>
                  </Fragment>
                )
              )}
            </div>
          </form>
        </Box>
      </Container>
      }
    </div>
  )
});

export default UploadToEvaluation;
