import Select from "react-select";
import React, {useEffect, useState} from "react";
import makeAnimated from "react-select/animated/dist/react-select.esm";

const animatedComponents = makeAnimated();

export const UsersSelect = ({users, allUsers, onChange, ...props}) => {
    const [options, setOptions] = useState([]);
    const [values, setValues] = useState([]);

    useEffect(() => {
        setOptions(
            allUsers.map(user => { return {value: user.id, label: user.firstName+" "+user.lastName}})
        )
    }, [allUsers]);

    useEffect(() => {
        console.log("Users", users, options);
        if (users)
            setValues(options.filter(u => users.some(u2 => u.value === u2.id)))
    }, [users, options])

    const doChange = (values) => {
        if (!Array.isArray(values)) {
            if (onChange) onChange([]);
        } else {
            if (onChange) onChange(allUsers.filter(u => values.some(u2 => u2.value === u.id)));
        }
    }

    return (
        <Select
            options={options}
            components={animatedComponents}
            isClearable
            isMulti
            value={values}
            onChange={doChange}
            styles={
                {
                    menu: provided => ({...provided, zIndex: 1052})
                }
            }
            {...props}
        />
    )
}
