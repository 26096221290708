import {connect} from "react-redux";
import {hideHeader} from "../header/actions";
import {withRouter} from "react-router";
import React, {useEffect, useState} from "react";
import {Fetch} from "../../common/lib/api-fetch";
import {API_BASE_URL, AppRoles} from "../../constants";
import {roundNumber} from "../../common/lib/math";
import {makeStyles} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Lightbox from "react-image-lightbox";
import {ButtonBlack} from "../../common/components/color-button";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'black'
  },
  mine: {
    backgroundColor: 'rgb(50,50,50)'
  }
}));

const VotingResult = ({match, history, user}) => {
  const classes = useStyles();
  const [voteData, setVotingData] = useState(null);
  const [result, setResult] = useState(null);
  const votingId = match.params.votingId;
  const [lbImages, setLbImages] = useState(null);
  const [showList, setShowList] = useState(false);
  const [presenterResultId, setPresenterResultId] = useState(null);

  const categories = {
    'wow': {'title': 'Wow / Impact', min: 15, max: 100, percent: 40},
    'technical': {'title': 'Teknisk', min: 15, max: 100, percent: 30},
    'composition': {'title': 'Komposisjon', min: 15, max: 100, percent: 30}
  };

  const fetchVotingData = async () => {
    await Fetch(API_BASE_URL + "/voting/" + votingId + "/voteResult", 'GET', {}).then(result => {
      setVotingData(result.data);
    })
  };

  useEffect(() => {
    if ('roles' in user && !user.roles.some(role => role.roleName === AppRoles.PRESENTER)) {
      setShowList(true);
    }
  }, [user]);

  useEffect(() => {
    if (result)
      setPresenterResultId(Math.min(result.length, voteData.showCount) - 1);
  }, [result]);

  useEffect(() => {
    if (presenterResultId !== null) {
      if (presenterResultId === -1) {
        setShowList(true);
        return;
      }
      if (result.length < presenterResultId + 1)
        setPresenterResultId(presenterResultId - 1);
    }
  }, [presenterResultId]);

  const getImageUrl = (image) => {
    return API_BASE_URL + "/image/" + image.uuid;
  }

  useEffect(() => {
    hideHeader();
  }, []);

  useEffect(() => {
    if (votingId)
      fetchVotingData();
  }, [votingId]);

  useEffect(() => {
    if (voteData) {
      const data = voteData.images.map(image => {
        const voting = voteData.votingImages.find(i => i.id === image.id);
        return {...image, mine: voting.mine, answers: voting.answers, uuid: voting.uuid, title: voting.title}
      })
      setResult(data.sort((a,b) => a.place-b.place));
    }
  }, [voteData]);

  useEffect(() => {
    console.log("Result", result);
  }, [result])
  return (
    <>
      {
        result !== null &&
        <Box width={1} className={classes.root}>
          <Container className={classes.root}>
            <div className={"full-height"}>
              {
                !showList && presenterResultId !== null && presenterResultId > -1 && (
                  <>
                    <Row className="align-content-center text-center text-white animate fadeIn">
                      <Col md={3}>
                      </Col>
                      <Col md={6} className="m-2">
                        {result[presenterResultId].place}. Plass<br/>
                        {'fullname' in result[presenterResultId] && result[presenterResultId].fullname}
                      </Col>
                      <Col md={3}>
                      </Col>
                    </Row>
                    <Row className="align-content-center animate fadeIn">
                      <Col md={3}>
                      </Col>
                      <Col md={6}>
                        <img className={"img-fluid shadow vh70"} src={getImageUrl(result[presenterResultId])}
                             alt={result[presenterResultId].title}
                             onClick={() => setLbImages([getImageUrl(result[presenterResultId])])}/>
                      </Col>
                      <Col md={3}>
                      </Col>
                    </Row>
                    <Row className={"justify-content-center mt-3"}>
                      <ButtonBlack onClick={() => setPresenterResultId(presenterResultId - 1)}>Neste</ButtonBlack>
                    </Row>
                  </>
                )
              }
              {
                showList && result.map((image, index) => {
                    return (
                      <Row key={image.uuid} className={image.mine ? classes.mine : ''}>
                        <Col md={3}>
                        </Col>
                        <Col md={2} className="m-2">
                          <img className={"vote-result img-fluid shadow"} src={getImageUrl(image)} alt={image.title}
                               onClick={() => setLbImages([getImageUrl(image)])}/>
                        </Col>
                        <Col md={4} className="m-2 white-text">
                          <Container>
                            <Row><Col>Plass:</Col><Col>{image.place}</Col></Row>

                            {'fullname' in image &&
                            <Row><Col>Navn:</Col><Col>{image.fullname}</Col></Row>
                            }
                            <Row><Col>Resultat:</Col><Col>{roundNumber(image.score, 1)} poeng</Col></Row>
                            {!('bestof' in voteData) &&
                            <>
                              <Row>
                                <Col>Wow:</Col>
                                <Col>
                                  <Tooltip title="Summen av alle som stemte" placement="top">
                                    <span>{roundNumber(image.wow, 2)} %</span>
                                  </Tooltip>
                                  {!image.mine && image.answers &&
                                  <Tooltip title="Det du stemte" placement="top">
                                  <span
                                    className={image.wow > image.answers.wow + 10 ? "red-text" : image.wow < image.answers.wow - 10 ? "red-text" : 'green-text'}> ({roundNumber(image.answers.wow, 0)} %)</span>
                                  </Tooltip>
                                  }
                                </Col>
                              </Row>
                              <Row>
                                <Col>Teknisk:</Col>
                                <Col>
                                  <Tooltip title="Summen av alle som stemte" placement="top">
                                    <span>{roundNumber(image.technical, 2)} %</span>
                                  </Tooltip>
                                  {!image.mine && image.answers &&
                                  <Tooltip title="Det du stemte" placement="top">
                                  <span
                                    className={image.technical > image.answers.technical + 10 ? "red-text" : image.technical < image.answers.technical - 10 ? "red-text" : 'green-text'}> ({roundNumber(image.answers.technical, 0)} %)</span>
                                  </Tooltip>
                                  }
                                </Col>
                              </Row>
                              <Row>
                                <Col>Komposisjon:</Col>
                                <Col>
                                  <Tooltip title="Summen av alle som stemte" placement="top">
                                    <span>{roundNumber(image.composition, 2)} %</span>
                                  </Tooltip>
                                  {!image.mine && image.answers &&
                                  <Tooltip title="Det du stemte" placement="top">
                                  <span
                                    className={image.composition > image.answers.composition + 10 ? "red-text" : image.composition < image.answers.composition - 10 ? "red-text" : 'green-text'}> ({roundNumber(image.answers.composition, 0)} %)</span>
                                  </Tooltip>
                                  }
                                </Col>
                              </Row>
                              {voteData.themePoints > 0 &&
                              <Row><Col>Tema:</Col><Col>{image.theme ? "Ja" : "Nei"}</Col></Row>
                              }
                            </>
                            }
                            {1 === 0 &&
                            <Row>
                              <Col>
                                Årspoeng:
                              </Col>
                              <Col>
                                <Tooltip title="Poeng i årskonkurransen" placement="top">
                                  <span>{roundNumber(image.categoryPoints, 0)}</span>
                                </Tooltip>
                              </Col>
                            </Row>
                            }
                          </Container>
                        </Col>
                        <Col md={3}></Col>
                      </Row>)
                  }
                )
              }
              {showList &&
              <Row className={"justify-content-center"}>
                <ButtonBlack onClick={() => history.push("/dashboard")}>Tilbake</ButtonBlack>
              </Row>
              }
            </div>
          </Container>
        </Box>
      }
      {lbImages !== null && (
        <Lightbox
          mainSrc={lbImages[0]}
          onCloseRequest={() => setLbImages(null)}
        />
      )}

    </>
  );
}

const mapStateToProps = state => ({
  user: state.user.user
});

const connected = connect(mapStateToProps, {hideHeader})(VotingResult);

export default withRouter(connected);
