import React, {useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {
    MDBCardBody,
    MDBCol,
    MDBNav,
    MDBNavItem,
    MDBNavLink,
    MDBRow,
    MDBTabContent,
    MDBTabPane
} from "mdbreact";
import Users from "./Users";
import {Groups} from "./components/Groups";

const Admin = () => {
    const [activeTab, setActiveTab] = useState("users");

    return (
        <MDBCol>
            <MDBRow>
                <MDBCol md="2">
                    <MDBNav pills color="primary" className="flex-column">
                        <MDBNavItem key="users">
                            <MDBNavLink to="#" className={activeTab === "users" ? "active" : ""}
                                        onClick={() => setActiveTab("users")}>
                                Brukere
                            </MDBNavLink>
                        </MDBNavItem>
                      <MDBNavItem key={"groups"}>
                        <MDBNavLink to="#" className={activeTab === "groups" ? "active" : ""}
                                    onClick={() => setActiveTab("groups")}>
                          Grupper
                        </MDBNavLink>
                      </MDBNavItem>
                    </MDBNav>
                </MDBCol>
                <MDBCol md="9">
                    <MDBTabContent activeItem={activeTab} className="p-0">
                        <MDBTabPane key="users" tabId="users" className="p-0">
                            <MDBCardBody>
                            <Users/>
                            </MDBCardBody>
                        </MDBTabPane>
                    </MDBTabContent>
                  <MDBTabContent activeItem={activeTab} className={"p-0"}>
                    <MDBTabPane key={"groups"} tabId={"groups"} className={"p-0"}>
                      <MDBCardBody>
                        <Groups/>
                      </MDBCardBody>
                    </MDBTabPane>
                  </MDBTabContent>
                </MDBCol>
            </MDBRow>
        </MDBCol>
    )
}

const mapStateToProps = state => ({
    user: state.auth.module.user,
    pathname: state.router.location.pathname,
});

const connected = connect(mapStateToProps, {})(Admin);

export default withRouter(connected);
